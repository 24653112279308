import casesTypesOptions from "@/data/generic/casesTypes";

let fields = (self, role, isForCreate) => {
  let roles = [];
  let roleNames = ["manager", "lawyer", "supercustomer", "customer", "public"];
  if (role === "admin") {
    roleNames = ["admin"].concat(roleNames);
  }
  roleNames.forEach((alias) => roles.push({ id: alias, alias }));

  let attributes = [];
  attributes.push({
    size: "1/2",
    type: "input",
    attribute: "email",
    validation: "required",
    readonly: !isForCreate,
  });
  if (isForCreate) {
    attributes.push({
      size: "1/2",
      type: "input",
      inputType: "password",
      attribute: "password",
      validation: "required",
    });
  }
  attributes.push({
    size: "1/2",
    type: "input",
    attribute: "alias",
    validation: "required",
  });
  attributes.push({
    size: "1/2",
    type: "select",
    attribute: "role",
    validation: "required",
    valueFormatter(value) {
      return value ? value.id : value;
    },
    extra: { options: roles },
  });
  attributes.push({
    size: "1/2",
    type: "select",
    attribute: "customer",
    validation: "required",
    extra: { options: self.customers },
    visibility(data) {
      return ["customer", "supercustomer"].includes(data.role);
    },
  });
  attributes.push({
    size: "1/2",
    type: "multiple-select",
    attribute: "establishments",
    validation: "required",
    valueFormatter(value) {
      return value ? value.id : value;
    },
    extra: {
      optionsFunction: () => {
        return self.establishments;
      },
    },
    visibility(data) {
      let newCustomerId = data.customer ? data.customer.id : null;
      if (self.customerId != newCustomerId) {
        //Cambio el customer, borramos los establecimientos...
        if (self.customerId != null) {
          data.establishments = [];
        }
        self.customerId = newCustomerId;
      }
      return !!data.customer && ["customer"].includes(data.role);
    },
  });
  attributes.push({
    size: "1/2",
    type: "multiple-select",
    attribute: "customers",
    valueFormatter(value) {
      return value ? value.id : value;
    },
    extra: {
      optionsFunction: () => {
        return self.customers;
      },
    },
    visibility(data) {
      return ["lawyer"].includes(data.role);
    },
  });
  attributes.push({
    size: "1/2",
    type: "multiple-select",
    attribute: "establishments",
    valueFormatter(value) {
      return value ? value.id : value;
    },
    extra: {
      optionsFunction: () => {
        return self.establishments;
      },
    },
    visibility(data) {
      let newCustomersIds = data.customers ? data.customers : null;
      if (
        (self.lawyerCustomersIds || []).join(",") !=
        (newCustomersIds || []).join(",")
      ) {
        //Cambio el customer, borramos los establecimientos...
        if (self.lawyerCustomersIds != null) {
          data.establishments = [];
        }
        self.lawyerCustomersIds = newCustomersIds;
      }
      return (
        !!data.customers &&
        data.customers.length > 0 &&
        ["lawyer"].includes(data.role)
      );
    },
  });

  const isMe =
    !isForCreate &&
    self.$route.params.id === self.$store.state.AppActiveUser.uid;

  attributes.push({
    size: "1/2",
    type: "multiple-select",
    attribute: "casesTypes",
    valueFormatter(value) {
      return value ? value.id : value;
    },
    extra: { options: casesTypesOptions },
    visibility(data) {
      return (
        !isMe &&
        !!data.role &&
        !["customer", "supercustomer", "public"].includes(data.role)
      );
    },
  });
  return attributes;
};

export default fields;
