<template>
  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :id="$route.params.id"
    :showDeleteButton="$route.params.id !== $store.state.AppActiveUser.uid"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { db } from "@/firebase/firebaseConfig";

import FormPage from "@/components/penal-retail-helpers/FormPage";

import usersFormConfig from "@/helpers/usersFormConfig";

let collectionName = "users";

export default {
  components: {
    FormPage,
  },
  computed: {
    ...mapGetters(["allEstablishments"]),
    tabs() {
      let attributes = usersFormConfig(
        this,
        this.$store.state.AppActiveUser.role
      );
      return [
        { name: `${collectionName}.tabs.general`, attributes: attributes },
      ];
    },
    establishments() {
      let customersIds = [];
      if (this.customerId) {
        customersIds = [this.customerId];
      } else if (this.lawyerCustomersIds) {
        customersIds = this.lawyerCustomersIds;
      }
      return this.allEstablishments.filter(
        (x) => x.id !== "empresa" && customersIds.includes(x.customer.id)
      );
    },
  },
  data() {
    return {
      customerId: null,
      lawyerCustomersIds: null,
      customers: [],
      collectionName,
    };
  },
  created() {
    this.$bind(
      "customers",
      db.collection("customers").where("deleted", "==", false).orderBy("alias"),
      { wait: true }
    );
  },
  methods: {
    prevalidate(/*data, collection, errors*/) {
      return false;
    },
  },
};
</script>
